import { Prisma } from "@prisma/client";
import { LinkDescriptor } from "@remix-run/node";
import { type MetaDescriptor } from "@remix-run/react";

/**
 * Format a `title` MetaDescriptor for app pages
 */
export const pageTitle = (
  title: string | null
): MetaDescriptor & {
  title: string;
} => {
  if (title) {
    return { title: `${title} • Infilla` };
  } else {
    return { title: "Infilla" };
  }
};

export const appFaviconLink = (): LinkDescriptor => {
  return { rel: "icon", href: "/favicon-48.png" };
};

/**
 * Meta tags for an organization's citizen-facing page
 */
export const citizenPageMeta = ({
  title,
  organization,
}: {
  title: string | null;
  organization: Prisma.OrganizationGetPayload<{
    select: { name: true; jurisdictionPlaceId: true };
  }>;
}): MetaDescriptor[] => {
  return [
    citizenPageTitle({ title, organization }),
    { ...citizenFaviconLink({ organization }), tagName: "link" },
  ];
};

/**
 * Page title for an organization's citizen-facing page
 */
const citizenPageTitle = ({
  title,
  organization,
}: {
  title: string | null;
  organization: Prisma.OrganizationGetPayload<{
    select: { name: true; jurisdictionPlaceId: true };
  }>;
}): MetaDescriptor => {
  if (title) {
    return { title: `${title} • ${organization.name}` };
  } else {
    return { title: organization.name };
  }
};

/**
 * Favicon link for an organization's citizen-facing page
 */
const citizenFaviconLink = ({
  organization,
}: {
  organization: Prisma.OrganizationGetPayload<{
    select: { jurisdictionPlaceId: true };
  }>;
}): LinkDescriptor => {
  switch (organization.jurisdictionPlaceId) {
    case 1657246:
      return {
        rel: "icon",
        href: "/images/organizationFavicons/sonomaCounty.png",
      };
    case 2410939:
      return {
        rel: "icon",
        href: "/images/organizationFavicons/cityofRichmond.png",
      };
    case 2411786:
      return {
        rel: "icon",
        href: "/images/organizationFavicons/sanFrancisco.png",
      };

    default:
      return appFaviconLink();
  }
};
